import React from "react";
import Layout from "../layout/Layout";

const NotFound = () => {
    return (
        <Layout>
            <div className="section">NotFound</div>
        </Layout>
    )
};

export default NotFound;
